<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent
          class="py-8"
          title="Standardized Detonator Verification Test"
          size="large"
        />

        <p class="font-bold">
          The Standardized Detonator Verification Test is used to determine the
          Detonator Number based on the Sand Crush Test result. Standardized
          detonators are used in a number of In-Process tests including the Cap
          Sensitivity test, Gap tests, Shock sensitivity, and TNT Equivalency.
        </p>

        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              VERIFICATION OF STANDARDIZATION
            </h2>

            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Testing can be completed by any lab to verify standardization
              efforts using the procedures and steps outlined here. The steps
              below further detail completion of the verification or Round Robin
              testing completed by the ET Users Group.
            </p>

            <ol class="p-light list-decimal pl-10 mt-4">
              <li>Download and implement the above detailed procedures,</li>
              <li>
                Obtain or build a Sand Test Apparatus per the drawings specified
                under Support Documents,
              </li>
              <li>Obtain quartz sand as well as standard sieves,</li>
              <li>
                Obtain a detonator for testing,
              </li>
              <li>
                Complete testing according to the detailed instructions in the
                respective standard Round Robin/VoS procedure (link at right
                under 'Standardized Procedures', if not shown, request access
                <router-link
                  style="color:#ef9a3d;"
                  to="/standardization-and-verification/round-robin-participation"
                  >here</router-link
                >), and
              </li>
              <li>
                Share your results with the ET Users Group community. You can
                <router-link class="link" to="/contact-us"
                  >contact us here</router-link
                >.
              </li>
            </ol>

            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Sand Crush Test Details
            </h2>

            <p class="p-light mb-4">
              The Sand Crush test apparatus (drawings in the Support Documents
              section) is used to contain the effects of a detonator firing
              while capturing the effect of the explosion on the size reduction
              to the sand or quartz particles. The quantity of material that
              then passes through the #40 sieve is related to the detonator
              strength. Verification of a given strength of detonator can then
              be completed. Several example test images are below.
            </p>

            <a :href="require('../../assets/setup.png')" target="_blank">
              <img class="m-auto" src="@/assets/setup.png" />
            </a>

            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ET Users Group Results (To Date)
            </h2>
            <div>
              <div class="p-light mb-6">
                <p>
                  Below is a plot of the data from significant testing and
                  development with the Sand Crush test completed by Storm and
                  Cope as referenced by the Bureau of Mines in "The Sand Test
                  for Determining the Strength of Detonators" (1916). That
                  original data is in the orange markers on the plot using
                  mercury fulminate and potassium chlorate labeled in the plot
                  as Type 1. Testing was completed with multiple sizes. Recent
                  tests were completed with #8 detonators from two different
                  manufacturers with one having a base PETN charge of 0.45 grams
                  (green marker) and the other with 0.8 gram PETN base charge
                  (blue marker).
                </p>
              </div>
              <div class="p-light mt-6 mb-6">
                <p>
                  The intent is that additional laboratories would complete
                  testing with #8 detonators and the results will be compared to
                  further validate the proposed standardized method and
                  procedure.
                </p>
              </div>
              <div>
                <p>
                  <a
                    :href="require('../../assets/initialresults.png')"
                    target="_blank"
                  >
                    <img class="m-auto" src="@/assets/initialresults.png" />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <div
                v-if="$store.getters['user/userClasses']['role-detonator-test']"
              >
                <h2
                  class="mb-6"
                  style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
                >
                  STANDARDIZED PROCEDURES
                </h2>
                <div class="mb-6 p-light">
                  <ol class="pl-10 list-decimal">
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/Standard%20Detonator%20Verification%20Procedure%20v3.pdf?alt=media&token=4658c4be-cfab-40a1-8fff-f9d7ac9db787"
                        target="_blank"
                        rel="noreferrer noopener"
                        >ETUG Standard Detonator Verification Test Procedure</a
                      >
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Support Documents
            </h2>
            <div class="mb-6 p-light">
              <a
                :href="require('../../assets/apparatuswdrwg.png')"
                target="_blank"
              >
                <img class="m-auto" src="@/assets/apparatuswdrwg.png" />
              </a>
              <p>
                The below downloadable drawing files show the dimensions of the
                Sand Crush test apparatus which is consistent with the original
                1916 design by the Bureau of Mines.
              </p>
              <ul class="pl-10 list-decimal">
                <li>
                  <a
                    class="link"
                    href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ST-01.zip?alt=media&token=aad4425b-3fab-4a69-85c3-b61405bc58ff"
                    rel="noopener noreferrer"
                    >Sand Crush Test Apparatus Drawings</a
                  >
                  (.zip file)
                </li>
              </ul>
            </div>
            <p class="p-light">
              An additional supporting document is the 7th Edition of the UN
              Manual where in Appendix 1 is specified several standard
              detonators with either 0.6 or 0.4 gram PETN charges.
            </p>
          </div>
          <div class="w-full">
            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Feedback
            </h2>
            <p class="p-light">
              If you have any feedback on the information found here or have
              completed testing please share your feedback and/or results with
              us
              <router-link class="link" to="/contact-us"
                >(Contact Us)</router-link
              >.
            </p>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-12 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
